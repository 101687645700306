const Bio = () => {
    return (
      <div className={"bio"}>
        <p style={{textAlign:"justify"}}>
          EL-Tan is a Zambian-born artist who started rapping at age 3 and started
          playing instruments at age 4 such as piano, guitar, drums, saxophone,
          and bass guitar. He started producing at age 9 and has been producing
          all his music since. His main inspiration is his father/teacher Elton
          Nyirenda who is an amazing Jazz pianist. Jazz, Hip hop, Blues, Seben,
          and Gospel are some of the genres that have shaped and influenced
          EL-Tans music. With artists like Chick Corea, Robert Glasper, John
          Mayer, BB King, John P kee, Marvin Sapp, Meek Mill, Lil Wayne, and J
          cole. With God/Jesus at the center of it all. For more information
          please get in touch.
        </p>
      </div>
    );
  };
  
  export default Bio;
  
  